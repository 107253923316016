import { useEffect, useState } from "react";
import styles from "./Categoria.module.scss";
//import sendLabSms from "../../../../extras/sendLabSMS";

const CategoriaComponent = ({ tipo, data, fecha, envio }) => {
  useEffect(() => {
    if (!data.sms) {
      return;
    } else {
      setLlamada(data.sms.some((item) => item === "Llamada"));
      setProfeco(data.sms.some((item) => item === "Profeco"));
      setDocumentos(data.sms.some((item) => item === "Documentos"));
    }
  }, [data]);

  const [show, setShow] = useState(false);
  const [documentos, setDocumentos] = useState(false);
  const [profeco, setProfeco] = useState(false);
  const [llamada, setLlamada] = useState(false);

  const datosCategoria = (
    <div className={styles.categoria}>
      <div className={styles.categoria_cabecera} onClick={() => setShow(!show)}>
        <h1>Datos de contacto</h1>
      </div>

      <div
        className={`${styles.categoria_cuerpo}  ${
          show ? styles.cuerpo_show : styles.cuerpo_hide
        }`}
      >
        <ul>
          <li>
            <b>Correo: </b>
            {data.correo}
          </li>
          <li>
            <b>Telefono: </b>

            <a
              href={`https://wa.me/+52${
                !data.telefono ? "" : data.telefono.replace(/ /g, "")
              }`}
              target="_blank"
              rel="noreferrer"
            >
              {!data.telefono ? "" : data.telefono.replace(/ /g, "")}
            </a>
          </li>
        </ul>
      </div>
    </div>
  );

  const smsCategoria = (
    <div className={styles.categoria}>
      <div className={styles.categoria_cabecera} onClick={() => setShow(!show)}>
        <h1>Envio de SMS</h1>
      </div>

      <div
        className={`${styles.categoria_cuerpo}  ${
          show ? styles.cuerpo_show : styles.cuerpo_hide
        }`}
      >
        <ul>
          <li>
            <p>Numero de telefono</p>
            <button
              className={styles.button}
              disabled={llamada}
              onClick={() => {
                envio(data._id, data.telefono, "Llamada");
                setLlamada(true);
              }}
            >
              Enviar
            </button>
          </li>

          <li>
            <p>Registro PROFECO</p>
            <button
              className={styles.button}
              disabled={profeco}
              onClick={() => {
                envio(data._id, data.telefono, "Profeco");
                setProfeco(true);
              }}
            >
              Enviar
            </button>
          </li>
          <li>
            <p>Documentos (Fisica y moral) </p>
            <button
              className={styles.button}
              disabled={documentos}
              onClick={() => {
                envio(data._id, data.telefono, "Documentos");
                setDocumentos(true);
              }}
            >
              Enviar
            </button>
          </li>
        </ul>
      </div>
    </div>
  );

  const pagoCategoria = (
    <div className={styles.categoria}>
      <div className={styles.categoria_cabecera}>
        <h1>
          {data.asesor_nombre} <br />
          <span>{fecha}</span>
        </h1>
      </div>
    </div>
  );

  const solicitudCategoria = (
    <div className={styles.categoria}>
      <div className={styles.categoria_cabecera} onClick={() => setShow(!show)}>
        <h1>
          Solicitud <br />
          <span
            style={{
              color: !data.marca
                ? "red"
                : data.marca === "Prestamo 24"
                ? "red"
                : data.marca === "Bien Crediticio"
                ? "#0F769C"
                : "#BEA313",
            }}
          >
            {data.marca}
          </span>
        </h1>
        <h1 className={styles.fecha}>{fecha}</h1>
      </div>

      <div
        className={`${styles.categoria_cuerpo}  ${
          show ? styles.cuerpo_show : styles.cuerpo_hide
        }`}
      >
        <ul>
          <li>
            <b>Nombre: </b>
            {data.nombre}
          </li>
          <li>
            <b>Nombre: </b>
            {data.nombre}
          </li>
          <li>
            <b>Correo: </b>
            {data.correo}
          </li>
          <li>
            <b>Telefono: </b>
            {data.telefono}
          </li>
          <li>
            <b>Horario: </b>
            {data.horario}
          </li>
          <br />
          <li>
            <b>Monto: </b>
            {data.monto}
          </li>
          <li>
            <b>Mensaje: </b>
            {data.mensaje}
          </li>
        </ul>
      </div>
    </div>
  );

  const solicitudNuevaCategoria = (
    <div className={styles.categoria}>
      <div className={styles.categoria_cabecera} onClick={() => setShow(!show)}>
        <h1>
          Solicitud <br />
          <span
            style={{
              color: !data.marca
                ? "red"
                : data.marca === "Prestamo 24"
                ? "red"
                : data.marca === "Bien Crediticio"
                ? "#0F769C"
                : "#BEA313",
            }}
          >
            {data.marca}
          </span>
        </h1>
        <h1 className={styles.fecha}>{fecha}</h1>
      </div>

      <div
        className={`${styles.categoria_cuerpo}  ${
          show ? styles.cuerpo_show : styles.cuerpo_hide
        }`}
      >
        <ul>
          <li>
            <b>Nombre: </b>
            {data.nombre}
          </li>
          <li>
            <b>Correo: </b>
            {data.correo}
          </li>
          <li>
            <b>Telefono: </b>
            {data.telefono}
          </li>
          <li>
            <b>Horario: </b>
            {data.horario}
          </li>
          <br />
          <li>
            <b>Monto: </b>
            {data.monto}
          </li>
          {data.monto === "Menos de $49,000" ? (
            <li>
              <b>Mensaje: </b>
              {data.mensaje}
            </li>
          ) : (
            <></>
          )}
        </ul>
      </div>
    </div>
  );

  const viabilidadFisicaCategoria = (
    <div className={styles.categoria}>
      <div className={styles.categoria_cabecera} onClick={() => setShow(!show)}>
        <h1>Viabilidad</h1>
        <h1 className={styles.fecha}>{fecha}</h1>
      </div>

      <div
        className={`${styles.categoria_cuerpo}  ${
          show ? styles.cuerpo_show : styles.cuerpo_hide
        }`}
      >
        <ul>
          <li>
            <b>Fecha: </b>
            {data.fecha}
          </li>
          <li>
            <b>Nombre: </b>
            {data.nombre_completo}
          </li>
          <li>
            <b>Telefono: </b>
            {data.numero}
          </li>
          <li>
            <b>Correo: </b>
            {data.correo}
          </li>
          <br />
          <li>
            <b>Tipo de solicitud: </b>
            {data.tipo}
          </li>
          <li>
            <b>Monto: </b>
            {data.monto}
          </li>
          <li>
            <b>Destino: </b>
            {data.motivo}
          </li>

          <br />
          <li>
            <b>Ocupacion: </b>
            {data.ocupacion}
          </li>

          <li>
            <b>Ingresos: </b>
            {data.ingresos}
          </li>
          <li>
            <b>Comprobante: </b>
            {data.comprobante}
          </li>

          <br />
          <li>
            <b>Gastos fijos: </b>
            {data.gastos_fijos}
          </li>

          <li>
            <b>Mensualidad deseada: </b>
            {data.mensualidad_deseada}
          </li>
          <li>
            <b>Monto renta: </b>
            {data.monto_renta}
          </li>

          <li>
            <b>Dependientes: </b>
            {data.dependientes_economicos}
          </li>
          <li>
            <b>Creditos activos: </b>
            {data.creditos_activos}
          </li>
          <br />

          <li>
            <a href={data.identificacion} target="_blank" rel="noreferrer">
              <b>Identificacion </b>
            </a>
          </li>
          <li>
            <a
              href={data.comprobante_ingresos}
              target="_blank"
              rel="noreferrer"
            >
              <b>Comprobante ingresos </b>
            </a>
          </li>
          <li>
            <a
              href={data.comprobante_domocilio}
              target="_blank"
              rel="noreferrer"
            >
              <b>Comprobante domicilio </b>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );

  const viabilidadMoralCategoria = (
    <div className={styles.categoria}>
      <div className={styles.categoria_cabecera} onClick={() => setShow(!show)}>
        <h1>Viabilidad</h1>
        <h1 className={styles.fecha}>{fecha}</h1>
      </div>

      <div
        className={`${styles.categoria_cuerpo}  ${
          show ? styles.cuerpo_show : styles.cuerpo_hide
        }`}
      >
        <ul>
          <li>
            <b>Fecha: </b>
            {data.fecha}
          </li>
          <li>
            <b>Nombre: </b>
            {data.nombre_completo}
          </li>
          <li>
            <b>Telefono: </b>
            {data.numero}
          </li>
          <li>
            <b>Correo: </b>
            {data.correo}
          </li>
          <br />
          <li>
            <b>Tipo de solicitud: </b>
            {data.tipo}
          </li>
          <li>
            <b>Monto: </b>
            {data.monto}
          </li>
          <li>
            <b>Uso: </b>
            {data.uso}
          </li>

          <br />
          <li>
            <b>Razon social: </b>
            {data.razon_social}
          </li>

          <li>
            <b>RFC: </b>
            {data.rfc}
          </li>
          <li>
            <b>Giro: </b>
            {data.giro}
          </li>
          <li>
            <b>Antiguedad: </b>
            {data.antiguedad}
          </li>
          <li>
            <b>Apoderado: </b>
            {data.apoderado}
          </li>

          <br />
          <li>
            <b>Facturacion mensual : </b>
            {data.facturacion_mensual}
          </li>

          <li>
            <b>Utilidad mensual : </b>
            {data.utilidad_mensual}
          </li>
          <li>
            <b>Cuentan con activos: </b>
            {data.activos}
          </li>

          <li>
            <a
              href={data.constancia_situacion}
              target="_blank"
              rel="noreferrer"
            >
              <b>Constancia situacion fiscal</b>
            </a>
          </li>

          <li>
            <a
              href={data.comprobante_domicilio}
              target="_blank"
              rel="noreferrer"
            >
              <b>Comprobante domicilio </b>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );

  const cotizacionCategoria = (
    <div className={styles.categoria}>
      <div className={styles.categoria_cabecera} onClick={() => setShow(!show)}>
        <h1>Cotización</h1>
        <h1 className={styles.fecha}>{fecha}</h1>
      </div>

      <div
        className={`${styles.categoria_cuerpo}  ${
          show ? styles.cuerpo_show : styles.cuerpo_hide
        }`}
      >
        <ul>
          <li>
            <b>Monto: </b>${data.monto}
          </li>
          <li>
            <b>Plazo: </b>
            {data.plazo} años
          </li>
          <li>
            <b>Tasa: </b>
            {data.tasa}% anual
          </li>
          <br />
          <li>
            <b>Mensualidad: </b>${data.mensualidad}
          </li>
          <li>
            <b>Total a liquidar: </b>${data.total}
          </li>
        </ul>
      </div>
    </div>
  );

  const aprobacionCategoria = (
    <div className={styles.categoria}>
      <div className={styles.categoria_cabecera} onClick={() => setShow(!show)}>
        <h1>Aprobación</h1>
        <h1 className={styles.fecha}>{fecha}</h1>
      </div>

      <div
        className={`${styles.categoria_cuerpo}  ${
          show ? styles.cuerpo_show : styles.cuerpo_hide
        }`}
      >
        <ul>
          <li>
            <b>Monto del resguardo: </b>${data.resguardo}
          </li>
          <br />

          <li>
            <b>Monto autorizado: </b>${data.monto}
          </li>
          <li>
            <b>Plazo elegido: </b>
            {data.plazo} años
          </li>
          <li>
            <b>Tasa: </b>
            {data.tasa}% anual
          </li>
          <li>
            <b>Mensualidad: </b>${data.mensualidad}
          </li>
        </ul>
      </div>
    </div>
  );

  const fichaCategoria = (
    <div className={styles.categoria}>
      <div className={styles.categoria_cabecera} onClick={() => setShow(!show)}>
        <h1>Ficha </h1>
        <h1 className={styles.fecha}>${data.monto}</h1>
      </div>

      <div
        className={`${styles.categoria_cuerpo}  ${
          show ? styles.cuerpo_show : styles.cuerpo_hide
        }`}
      >
        <ul>
          <li>
            <b>Concepto: </b>
            {data.concepto}
          </li>
          <br />

          <li>
            <b>Cuenta: </b>
            {data.cuenta}
          </li>
          <li>
            <b>Razon social: </b>
            {data.razon}
          </li>
          <li>
            <b>Marca: </b>
            {data.marca}
          </li>
        </ul>
      </div>
    </div>
  );

  const notaCategoria = (
    <div className={styles.categoria}>
      <div className={styles.categoria_cabecera} onClick={() => setShow(!show)}>
        <h1>
          Nota <br />
          <span
            style={{
              color: !data.marca
                ? "red"
                : data.marca === "Prestamo 24"
                ? "red"
                : data.marca === "Bien Crediticio"
                ? "#0F769C"
                : "#BEA313",
            }}
          >
            {data.asesor}
          </span>
        </h1>
        <h1 className={styles.fecha}>{fecha}</h1>
      </div>

      <div
        className={`${styles.categoria_cuerpo}  ${
          show ? styles.cuerpo_show : styles.cuerpo_hide
        }`}
      >
        <ul>
          <li>
            <b>Mensaje: </b>
            {data.texto}
          </li>
        </ul>
      </div>
    </div>
  );

  return {
    datos: datosCategoria,
    sms: smsCategoria,
    asignacion: pagoCategoria,
    solicitud: solicitudCategoria,
    solicitud_nueva: solicitudNuevaCategoria,
    viabilidadFisica: viabilidadFisicaCategoria,
    viabilidadMoral: viabilidadMoralCategoria,
    cotizacion: cotizacionCategoria,
    aprobacion: aprobacionCategoria,
    pago: <></>,
    ficha: fichaCategoria,
    nota: notaCategoria,
  }[tipo];
};

export default CategoriaComponent;

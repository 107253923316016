import ClientCard from "./ClientCard/ClientCard";
import styles from "./ClientsContainer.module.scss";

import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
  WindowScroller,
} from "react-virtualized";

const ClientsContainer = ({ clientes, loading, error, ...rest }) => {
  const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 100,
  });

  const renderRow = ({ index, key, style, parent }) => {
    return (
      <CellMeasurer
        key={key}
        cache={cache}
        parent={parent}
        columnIndex={0}
        rowIndex={index}
      >
        {({ registerChild }) => (
          <div style={style} ref={registerChild} className={styles.tarjeta}>
            <ClientCard
              details={clientes[index]}
              indice={key}
              key={key}
              {...rest}
            />
          </div>
        )}
      </CellMeasurer>
    );
  };

  return (
    <div className={styles.clientesContainer}>
      {!clientes.length ? (
        loading ? (
          <h2>Estamos cargando los datos .....</h2>
        ) : error === null ? (
          <h2>Aqui no hay clientes</h2>
        ) : (
          <h2>{error}</h2>
        )
      ) : (
        <div className={styles.list}>
          <AutoSizer>
            {({ width, height }) => (
              <List
                width={width}
                height={height}
                deferredMeasurementCache={cache}
                rowHeight={cache.rowHeight}
                rowRenderer={renderRow}
                rowCount={clientes.length}
                overscanRowCount={20}
                // className={styles.noScrollbar}
              />
            )}
          </AutoSizer>
        </div>
        /* clientes.map((item, key) => (
          <ClientCard details={item} indice={key} key={key} {...rest} />
        )) */
      )}
    </div>
  );
};

const conAutoSizer = ({ cache, renderRow, clientes }) => {
  return (
    <div className={styles.list}>
      <AutoSizer>
        {({ width, height }) => (
          <List
            width={width + 25}
            height={height}
            deferredMeasurementCache={cache}
            rowHeight={cache.rowHeight}
            rowRenderer={renderRow}
            rowCount={clientes.length}
            overscanRowCount={20}
          />
        )}
      </AutoSizer>
    </div>
  );
};

export default ClientsContainer;

import { Fragment, useEffect, useState } from "react";
import styles from "../ClientDetails.module.scss";
import CategoriaComponent from "./Categoria/CategoriaComponent";

const SinContactarComponent = ({
  details,
  hide,
  clienteContactado,
  agregarNota,
  aplazarContacto,
  cancelar,
  admin,
  enviarSMS,
  retirar,
}) => {
  const [metodo, setMetodo] = useState("correo");
  const [nota, setNota] = useState("");
  const [loading, setLoading] = useState(false);

  const resetForm = () => {
    setMetodo("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setLoading(true);

    clienteContactado(details._id, metodo).then((res) => {
      setLoading(true);
    });
  };

  const handleComSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (nota === "") {
      return;
    }

    setLoading(true);

    agregarNota(details._id, nota).then((res) => setLoading(false));
  };

  const handleCommentSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (nota === "") {
      return;
    }

    setLoading(true);

    aplazarContacto(details._id, nota).then((res) => setLoading(false));
  };

  const handleCancelaSubmit = (type, e) => {
    e.preventDefault();
    e.stopPropagation();
    if (nota === "") {
      return;
    }
    setLoading(true);

    cancelar(details._id, type, nota).then((res) => setLoading(false));
  };

  return (
    <Fragment>
      <div className={styles.cabecera}>
        <h1>Nuevo cliente</h1>
        <button
          onClick={() => {
            resetForm();
            hide();
          }}
          className={styles.closeBttn}
        >
          <span className="material-symbols-outlined">close</span>
        </button>
      </div>

      <div className={styles.body}>
        <div className={styles.tarjetaDatos}>
          <h1 style={{ textAlign: "center" }}>
            <b>{details.fecha_actualizacion}</b>
          </h1>
          <br />
          <h1 style={{ textAlign: "center" }}>{details.nombre}</h1>
        </div>

        <CategoriaComponent tipo="datos" data={details} />

        <CategoriaComponent tipo="sms" data={details} envio={enviarSMS} />

        {admin ? (
          <button
            className={styles.badButton}
            onClick={() => {
              setLoading(retirar(details._id));
            }}
          >
            Retirar para reciclaje
          </button>
        ) : (
          <></>
        )}

        <span style={{ border: ".5px solid #02394a" }} />
        <p>Asignaciones: {details.pago.length}</p>

        {details.pago.map((item, id) => (
          <CategoriaComponent
            key={`categoria_pago_${id}`}
            tipo="asignacion"
            fecha={item.fecha_asignado}
            data={item}
          />
        ))}

        <span style={{ border: ".5px solid #000" }} />
        <p>Solicitudes: {details.solicitud.length}</p>

        {details.solicitud.map((item, id) => (
          <CategoriaComponent
            key={`categoria_solicitud_${id}`}
            tipo="solicitud"
            fecha={item.fecha}
            data={item}
          />
        ))}

        {!details.viabilidad ? (
          <></>
        ) : (
          <Fragment>
            <span style={{ border: ".5px solid #02394a" }} />
            <p>Viabilidad: {details.viabilidad.length}</p>
            {details.viabilidad.map((item, id) => (
              <CategoriaComponent
                key={`categoria_viabilidad_${id}`}
                tipo={
                  item.tipo === "Persona fisica"
                    ? "viabilidadFisica"
                    : "viabilidadMoral"
                }
                fecha={item.fecha}
                data={item}
              />
            ))}
          </Fragment>
        )}

        {!details.cotizaciones ? (
          <></>
        ) : (
          <Fragment>
            <span style={{ border: ".5px solid #02394a" }} />

            {details.cotizaciones.map((item, id) => (
              <CategoriaComponent
                key={`categoria_cotiza_${id}`}
                tipo="cotizacion"
                fecha={item.fecha_cotizacion}
                data={item}
              />
            ))}
          </Fragment>
        )}

        {!details.aprobaciones ? (
          <></>
        ) : (
          <Fragment>
            <span style={{ border: ".5px solid #02394a" }} />

            {details.aprobaciones.map((item, id) => (
              <CategoriaComponent
                key={`categoria_aprobacion_${id}`}
                tipo="aprobacion"
                fecha={item.fecha_aprobacion}
                data={item}
              />
            ))}
          </Fragment>
        )}

        <span style={{ border: ".5px solid #02394a" }} />

        {!details.notas ? (
          <></>
        ) : (
          <Fragment>
            {details.notas.map((item, id) => (
              <CategoriaComponent
                key={`categoria_nota_${id}`}
                tipo="nota"
                fecha={item.fecha}
                data={item}
              />
            ))}
          </Fragment>
        )}

        {/* Agrega una nota */}
        <form>
          <div className={styles.comentario_form}>
            <div className={styles.comentario_cabecera}>
              <h1>Agregar una nota</h1>
            </div>
            <div className={styles.cotizacion_cuerpo}>
              <textarea
                required
                value={nota}
                onChange={(e) => {
                  setNota(e.target.value);
                }}
              ></textarea>

              {loading ? (
                <>Cargando ...</>
              ) : (
                <Fragment>
                  <button
                    onClick={handleComSubmit}
                    className={styles.passiveButton}
                  >
                    Guardar
                  </button>
                </Fragment>
              )}
            </div>
          </div>
        </form>

        <form onSubmit={handleSubmit} id="cotizacion_form">
          <div className={styles.cotizacion_form}>
            <div className={styles.cotizacion_cabecera}>
              <h1>Contactado</h1>
            </div>

            <div className={styles.cotizacion_cuerpo}>
              <div className={styles.cotizacion_field}>
                <label>Medio de contacto</label>
                <select
                  value={metodo}
                  onChange={(e) => setMetodo(e.target.value)}
                >
                  <option value="correo">Correo</option>
                  <option value="llamada">Llamada</option>
                  <option value="whatsapp">Whatsapp</option>
                </select>
              </div>
            </div>

            {loading ? (
              <>Cargando ...</>
            ) : (
              <button
                form="cotizacion_form"
                type="submit"
                className={styles.okButton}
              >
                Contactado
              </button>
            )}
          </div>
          {/* <br />
          <div className={styles.cotizacion_form}>
            <div className={styles.cotizacion_cabecera}>
              <h1>Nota (opcional)</h1>
            </div>

            <div className={styles.cotizacion_cuerpo}>
              <div className={styles.cotizacion_field}>
                <textarea
                  rows={8}
                  value={nota}
                  onChange={(e) => setNota(e.target.value)}
                />
              </div>
            </div>
          </div> */}
        </form>

        <form>
          <div className={styles.comentario_form}>
            <div className={styles.comentario_cabecera}>
              <h1>Da más detalles</h1>
            </div>
            <div className={styles.cotizacion_cuerpo}>
              <textarea
                required
                value={nota}
                onChange={(e) => {
                  setNota(e.target.value);
                }}
              ></textarea>

              {loading ? (
                <>Cargando ...</>
              ) : (
                <Fragment>
                  <button
                    onClick={handleCommentSubmit}
                    className={styles.passiveButton}
                  >
                    No contesta
                  </button>
                  <button
                    className={styles.badButton}
                    onClick={(e) => {
                      handleCancelaSubmit(100, e);
                    }}
                  >
                    No se puede contactar
                  </button>
                  <button
                    className={styles.badButton}
                    onClick={(e) => {
                      handleCancelaSubmit(101, e);
                    }}
                  >
                    No le interesa
                  </button>
                  <button
                    className={styles.badButton}
                    onClick={(e) => {
                      handleCancelaSubmit(99, e);
                    }}
                  >
                    Gastos médicos
                  </button>
                </Fragment>
              )}
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default SinContactarComponent;

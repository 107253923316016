import { Fragment, useState } from "react";
import styles from "../ClientDetails.module.scss";
import CategoriaComponent from "../SinContactar/Categoria";

const ClienteSinAsignarComponent = ({
  details,
  asignarCliente,
  hide,
  activeClient,
}) => {
  const [activeAsesor, setActiveAsesor] = useState(0);
  const [loading, setLoading] = useState(false);

  const asesores = [
    {
      nombre: "Jaime",
      artistico: "Carlos",
      id: "ilVW1Pnog2YZfxHnCaBhyUyXe0B3",
    },
    {
      nombre: "Nancy",
      artistico: "Valentina",
      id: "FzSDUttp7sMYdpxFYaowCyZXHTi2",
    },
    {
      nombre: "Citla",
      artistico: "Vanessa",
      id: "hL62KFPq5CY4x9FHGIexTp5aCZS2",
    },
    {
      nombre: "Osiel",
      artistico: "Sergio",
      id: "zfFwW1pZu7TdRx2Bju4OgOTi2o62",
    },
    {
      nombre: "Sam",
      artistico: "Pia Gonzalez",
      id: "VQPbPwsyVmMhbD4wQXXKIujmf8V2",
    },
    {
      nombre: "Betty",
      artistico: "Gerencia",
      id: "2TBSb8RFC5cfYm8yxw07cX7iQyr1",
    },
    {
      nombre: "Sandra",
      artistico: "Alessandra",
      id: "rpfmeUzCqwSkjJwbFpwaq7Px1ll1",
    },
    {
      nombre: "Marisol",
      artistico: "Sofia",
      id: "CdaxdmTvmKPiVE7ch3IwdLosJVw1",
    },
    {
      nombre: "Danna",
      artistico: "Andrea",
      id: "5s3PdMOkhhMC32Zz4kzc0YI6gMk2",
    },
    {
      nombre: "Ricardo",
      artistico: "Naim",
      id: "s9mWojqGsVaqT2d8oCZLrwNGBe62",
    },
    {
      nombre: "Freddy",
      artistico: "Ernesto",
      id: "nnA9hmiUd7QwLNV9S219NZN3Q852",
    },
    {
      nombre: "Sonia",
      artistico: "Sonia",
      id: "ymglgJpYJHM98coGj0dxfjSEE813",
    },
    {
      nombre: "Jesus",
      artistico: "Andres",
      id: "e8iTet3ZducBTeosJu9yf2DOkCs1",
    },
    {
      nombre: "Daniela",
      artistico: "Ximena",
      id: "G7MSJJQJ4tQx3xHj6nNblSuMjkM2",
    },
    {
      nombre: "Ernesto",
      artistico: "Alfredo",
      id: "3x9sYfuoL8YN8jpYcz7ooVl9Ot43",
    },
    {
      nombre: "Blanca",
      artistico: "Jazmin",
      id: "H5yDjLuNaEddHUvPwXpjfBSjhGw2",
    },
    {
      nombre: "Lizbeth",
      artistico: "Lucia",
      id: "o3wE9g1G98hWO2TKJaefdzXl6XO2",
    },
    {
      nombre: "Ana",
      artistico: "Esperanza",
      id: "uZM6qkz0luddKQV5XH0FVTCqKug1",
    },
    {
      nombre: "Anel",
      artistico: "Naomi",
      id: "30CN2MSq8SWSvniK0te3krQiT913",
    },
    {
      nombre: "Diana",
      artistico: "Isabel",
      id: "vAFXt7CrEGboKdvj0h1hniRBYSg2",
    },
    {
      nombre: "Bryan",
      artistico: "Cesar",
      id: "MRa4cZXOVodi52pMaq6SnR26xIK2",
    },
    {
      nombre: "Enrique",
      artistico: "Gustavo",
      id: "LpPfjfM8hQS0l9vsDQ5cGWQCx3A2",
    },
    {
      nombre: "Enrique Reyes",
      artistico: "Jose Luis Cruz",
      id: "sG69HABTJEN7WAxVcq0RdRMUvMf1",
    },
    {
      nombre: "Jonathan Becerra",
      artistico: "Marco Medina",
      id: "hFx7xCg3LkPQLqT4UoL8dA6Wrx03",
    },
    {
      nombre: "Vania Morales",
      artistico: "Veronica Rodriguez",
      id: "e3dlOUJkHrMPnUz2hiGgMjiD1Iv2",
    },
    {
      nombre: "Erika Licona",
      artistico: "Patricia Gonzalez",
      id: "NwOkSDOLHxRyuUvzxIuroHMsbXK2",
    },

    {
      nombre: "Vero",
      artistico: "Fernanda",
      id: "cD5hLJPmKicme3UtzUACINBIdcF2",
    },
    {
      nombre: "Autos",
      artistico: "Autos Autofin",
      id: "TpfVgFGgGXdcd4DptJDawNneAiL2",
    },

    {
      nombre: "Juan Luis",
      artistico: "Legal Zerodeudas",
      id: "qfkuuBGFx6VmJ7f8AAvKi39IZSD2",
    },
    {
      nombre: "Admin",
      artistico: "Tina",
      id: "CLD4kMC1YsXzjtj8jpyUcKxbfTq2",
    },
    {
      nombre: "Soporte",
      artistico: "wiki",
      id: "sE2jlhd26aO7f38x8a5m946hAhg1",
    },
  ];

  const onAsigna = () => {
    setLoading(true);

    asignarCliente(
      details._id,
      asesores[activeAsesor].nombre,
      asesores[activeAsesor].id,
      details.solicitud[0].monto
    ).then((res) => setLoading(false));
  };

  return (
    <div>
      <div className={styles.cabecera}>
        <h1>Sin asignar</h1>

        <button onClick={hide} className={styles.closeBttn}>
          <span className="material-symbols-outlined">close</span>
        </button>
      </div>

      <div className={styles.body}>
        <div className={styles.tarjetaDatos}>
          <h1>{details.nombre}</h1>
        </div>

        {!details.solicitud ? (
          <></>
        ) : (
          <Fragment>
            <span style={{ border: ".5px solid #02394a" }} />
            <p>Solicitudes: {details.solicitud.length}</p>

            {details.solicitud.map((item, id) => (
              <CategoriaComponent
                key={`categoria_solicitud_${id}`}
                tipo="solicitud_nueva"
                fecha={item.fecha}
                data={item}
              />
            ))}
          </Fragment>
        )}

        {!details.viabilidad ? (
          <></>
        ) : (
          <Fragment>
            <span style={{ border: ".5px solid #02394a" }} />
            <p>Viabilidad: {details.viabilidad.length}</p>
            {details.viabilidad.map((item, id) => (
              <CategoriaComponent
                key={`categoria_viabilidad_${id}`}
                tipo={
                  item.tipo === "Persona fisica"
                    ? "viabilidadFisica"
                    : "viabilidadMoral"
                }
                fecha={item.fecha}
                data={item}
              />
            ))}
          </Fragment>
        )}

        {!details.cotizaciones ? (
          <></>
        ) : (
          <Fragment>
            <span style={{ border: ".5px solid #02394a" }} />

            {details.cotizaciones.map((item, id) => (
              <CategoriaComponent
                key={`categoria_cotiza_${id}`}
                tipo="cotizacion"
                fecha={item.fecha_cotizacion}
                data={item}
              />
            ))}
          </Fragment>
        )}

        {!details.aprobaciones ? (
          <></>
        ) : (
          <Fragment>
            <span style={{ border: ".5px solid #02394a" }} />

            {details.aprobaciones.map((item, id) => (
              <CategoriaComponent
                key={`categoria_aprobacion_${id}`}
                tipo="aprobacion"
                fecha={item.fecha_aprobacion}
                data={item}
              />
            ))}
          </Fragment>
        )}

        <span style={{ border: ".5px solid #02394a" }} />

        {/* {activeClient === 0 ? ( */}
        {0 === 0 ? (
          <div className={styles.cuerpoAsigna}>
            <select
              value={activeAsesor}
              onChange={(e) => setActiveAsesor(e.target.value)}
            >
              {asesores.map((item, key) => (
                <option key={`option_${key}`} value={key}>
                  {item.nombre}
                </option>
              ))}
            </select>

            {loading ? (
              <>Cargando ...</>
            ) : (
              <button onClick={onAsigna}>Asignar</button>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default ClienteSinAsignarComponent;

import { Fragment, useState } from "react";
import styles from "../ClientDetails.module.scss";
import CategoriaComponent from "../SinContactar/Categoria";

const SinPagoComponent = ({ details, hide, agregarNota }) => {

  const [loading, setLoading] = useState(false);
  const [nota, setNota] = useState("");

  const handleComSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (nota === "") {
      return;
    }

    setLoading(true);

    agregarNota(details._id, nota).then((res) => setLoading(false));
  };
  return (
    <div>
      <div className={styles.cabecera}>
        <h1>Cancelado</h1>
        <button
          onClick={() => {
            hide();
          }}
          className={styles.closeBttn}
        >
          <span className="material-symbols-outlined">close</span>
        </button>
      </div>

      <div className={styles.body}>
        <div className={styles.tarjetaDatos}>
          <h1 style={{ textAlign: "center" }}>
            <b>{details.fecha_actualizacion}</b>
          </h1>
          <br />
          <h1 style={{ textAlign: "center" }}>{details.nombre}</h1>
        </div>

        <CategoriaComponent tipo="datos" data={details} />

        <span style={{ border: ".5px solid #000" }} />
        <p>Solicitudes: {details.solicitud.length}</p>

        {details.solicitud.map((item, id) => (
          <CategoriaComponent
            key={`categoria_solicitud_${id}`}
            tipo="solicitud"
            fecha={item.fecha}
            data={item}
          />
        ))}

        {!details.viabilidad ? (
          <></>
        ) : (
          <Fragment>
            <span style={{ border: ".5px solid #02394a" }} />
            <p>Viabilidad: {details.viabilidad.length}</p>
            {details.viabilidad.map((item, id) => (
              <CategoriaComponent
                key={`categoria_viabilidad_${id}`}
                tipo={
                  item.tipo === "Persona fisica"
                    ? "viabilidadFisica"
                    : "viabilidadMoral"
                }
                fecha={item.fecha}
                data={item}
              />
            ))}
          </Fragment>
        )}

        {!details.cotizaciones ? (
          <></>
        ) : (
          <Fragment>
            <span style={{ border: ".5px solid #02394a" }} />

            {details.cotizaciones.map((item, id) => (
              <CategoriaComponent
                key={`categoria_cotiza_${id}`}
                tipo="cotizacion"
                fecha={item.fecha_cotizacion}
                data={item}
              />
            ))}
          </Fragment>
        )}

        {!details.aprobaciones ? (
          <></>
        ) : (
          <Fragment>
            <span style={{ border: ".5px solid #02394a" }} />

            {details.aprobaciones.map((item, id) => (
              <CategoriaComponent
                key={`categoria_aprobacion_${id}`}
                tipo="aprobacion"
                fecha={item.fecha_aprobacion}
                data={item}
              />
            ))}
          </Fragment>
        )}


        <span style={{ border: ".5px solid #02394a" }} />

        {!details.notas ? (
          <></>
        ) : (
          <Fragment>
            {details.notas.map((item, id) => (
              <CategoriaComponent
                key={`categoria_nota_${id}`}
                tipo="nota"
                fecha={item.fecha}
                data={item}
              />
            ))}
          </Fragment>
        )}

        {/* Agrega una nota */}
        <form>
          <div className={styles.comentario_form}>
            <div className={styles.comentario_cabecera}>
              <h1>Agregar una nota</h1>
            </div>
            <div className={styles.cotizacion_cuerpo}>
              <textarea
                required
                value={nota}
                onChange={(e) => {
                  setNota(e.target.value);
                }}
              ></textarea>

              {loading ? (
                <>Cargando ...</>
              ) : (
                <Fragment>
                  <button
                    onClick={handleComSubmit}
                    className={styles.passiveButton}
                  >
                    Guardar
                  </button>
                </Fragment>
              )}
            </div>
          </div>
        </form>


      </div>

    </div>
  );
};

export default SinPagoComponent;

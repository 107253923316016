import { Fragment, useEffect, useState } from "react";
import styles from "../ClientDetails.module.scss";
import CategoriaComponent from "../SinContactar/Categoria";

const SinContactarComponent = ({
  details,
  hide,
  clienteSeguimiento,
  agregarNota,
  aplazarContacto,
  cancelar,
  admin,
  enviarSMS,
  retirar,
}) => {
  const [monto, setMonto] = useState("0");
  const [plazo, Setplazo] = useState("");
  const [tasa, setTasa] = useState("7");
  const [mensualidad, setMensualidad] = useState("");
  const [total, setTotal] = useState("");
  const [nota, setNota] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const interes =
      parseInt(monto.replace(/\./g, ""), 10) * (tasa / 100) * plazo;

    const total = parseInt(monto.replace(/\./g, ""), 10) + +interes;

    const pago = (total / (plazo * 12)).toFixed(2);

    setTotal(total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
    setMensualidad(pago.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
  }, [monto, plazo, tasa]);

  const resetForm = () => {
    setMonto("");
    Setplazo("");
    setTasa("7");
    setNota("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setLoading(true);

    const montoLimpio = monto.replace(/\./g, "");

    clienteSeguimiento(details._id, {
      monto: montoLimpio,
      plazo,
      tasa,
      mensualidad,
      total,
      //nota,
    }).then((res) => {
      setLoading(true);
    });
  };

  const handleComSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (nota === "") {
      return;
    }

    setLoading(true);

    agregarNota(details._id, nota).then((res) => setLoading(false));
  };

  const handleCommentSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (nota === "") {
      return;
    }

    setLoading(true);

    aplazarContacto(details._id, nota).then((res) => setLoading(false));
  };

  const handleCancelaSubmit = (type, e) => {
    e.preventDefault();
    e.stopPropagation();
    if (nota === "") {
      return;
    }
    setLoading(true);

    cancelar(details._id, type, nota).then((res) => setLoading(false));
  };

  return (
    <Fragment>
      <div className={styles.cabecera}>
        <h1>Por cotizar</h1>
        <button
          onClick={() => {
            resetForm();
            hide();
          }}
          className={styles.closeBttn}
        >
          <span className="material-symbols-outlined">close</span>
        </button>
      </div>

      <div className={styles.body}>
        <div className={styles.tarjetaDatos}>
          <h1 style={{ textAlign: "center" }}>
            <b>{details.fecha_actualizacion}</b>
          </h1>
          <br />
          <h1 style={{ textAlign: "center" }}>{details.nombre}</h1>
        </div>

        <CategoriaComponent tipo="datos" data={details} />

        <CategoriaComponent tipo="sms" data={details} envio={enviarSMS} />

        {admin ? (
          <button
            className={styles.badButton}
            onClick={() => {
              setLoading(retirar(details._id));
            }}
          >
            Retirar para reciclaje
          </button>
        ) : (
          <></>
        )}

        <span style={{ border: ".5px solid #02394a" }} />
        <p>Asignaciones: {details.pago.length}</p>

        {details.pago.map((item, id) => (
          <CategoriaComponent
            key={`categoria_pago_${id}`}
            tipo="asignacion"
            fecha={item.fecha_asignado}
            data={item}
          />
        ))}

        <span style={{ border: ".5px solid #000" }} />
        <p>Solicitudes: {details.solicitud.length}</p>

        {details.solicitud.map((item, id) => (
          <CategoriaComponent
            key={`categoria_solicitud_${id}`}
            tipo="solicitud"
            fecha={item.fecha}
            data={item}
          />
        ))}

        {!details.viabilidad ? (
          <></>
        ) : (
          <Fragment>
            <span style={{ border: ".5px solid #02394a" }} />
            <p>Viabilidad: {details.viabilidad.length}</p>
            {details.viabilidad.map((item, id) => (
              <CategoriaComponent
                key={`categoria_viabilidad_${id}`}
                tipo={
                  item.tipo === "Persona fisica"
                    ? "viabilidadFisica"
                    : "viabilidadMoral"
                }
                fecha={item.fecha}
                data={item}
              />
            ))}
          </Fragment>
        )}

        {!details.cotizaciones ? (
          <></>
        ) : (
          <Fragment>
            <span style={{ border: ".5px solid #02394a" }} />
            <p>Cotizaciones: {details.cotizaciones.length}</p>
            {details.cotizaciones.map((item, id) => (
              <CategoriaComponent
                key={`categoria_cotiza_${id}`}
                tipo="cotizacion"
                fecha={item.fecha_cotizacion}
                data={item}
              />
            ))}
          </Fragment>
        )}

        {!details.aprobaciones ? (
          <></>
        ) : (
          <Fragment>
            <span style={{ border: ".5px solid #02394a" }} />
            <p>Aprobaciones: {details.aprobaciones.length}</p>
            {details.aprobaciones.map((item, id) => (
              <CategoriaComponent
                key={`categoria_aprobacion_${id}`}
                tipo="aprobacion"
                fecha={item.fecha_aprobacion}
                data={item}
              />
            ))}
          </Fragment>
        )}

        <span style={{ border: ".5px solid #02394a" }} />

        {!details.notas ? (
          <></>
        ) : (
          <Fragment>
            {details.notas.map((item, id) => (
              <CategoriaComponent
                key={`categoria_nota_${id}`}
                tipo="nota"
                fecha={item.fecha}
                data={item}
              />
            ))}
          </Fragment>
        )}

        {/* Agrega una nota */}
        <form>
          <div className={styles.comentario_form}>
            <div className={styles.comentario_cabecera}>
              <h1>Agregar una nota</h1>
            </div>
            <div className={styles.cotizacion_cuerpo}>
              <textarea
                required
                value={nota}
                onChange={(e) => {
                  setNota(e.target.value);
                }}
              ></textarea>

              {loading ? (
                <>Cargando ...</>
              ) : (
                <Fragment>
                  <button
                    onClick={handleComSubmit}
                    className={styles.passiveButton}
                  >
                    Guardar
                  </button>
                </Fragment>
              )}
            </div>
          </div>
        </form>

        <form onSubmit={handleSubmit} id="cotizacion_form">
          <div className={styles.cotizacion_form}>
            <div className={styles.cotizacion_cabecera}>
              <h1>Cotización</h1>
            </div>

            <div className={styles.cotizacion_cuerpo}>
              <div className={styles.cotizacion_field}>
                <label>Monto solicitado</label>
                <div className={styles.cotizacion_input_inicio}>
                  <input
                    required
                    value={parseInt(monto.replace(/\./g, ""), 10)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                    onChange={(e) => {
                      e.target.value === ""
                        ? setMonto("")
                        : setMonto(e.target.value);
                    }}
                  />
                  <span>$</span>
                </div>
              </div>
              <div className={styles.cotizacion_field}>
                <label>Plazo</label>

                <div className={styles.cotizacion_input_final}>
                  <input
                    required
                    value={plazo}
                    onChange={(e) => Setplazo(e.target.value)}
                  />
                  <span>años</span>
                </div>
              </div>
              <div className={styles.cotizacion_field}>
                <label>Tasa de interés</label>

                <div className={styles.cotizacion_input_final}>
                  <input
                    required
                    value={tasa}
                    onChange={(e) => setTasa(e.target.value)}
                  />
                  <span>%</span>
                </div>
              </div>
              <div className={styles.cotizacion_field}>
                <label>Mensualidad</label>
                <div className={styles.cotizacion_input_inicio}>
                  <input
                    value={mensualidad}
                    onChange={(e) => setMensualidad(e.target.value)}
                    disabled
                  />
                  <span>$</span>
                </div>
              </div>
              <div className={styles.cotizacion_field}>
                <label>Total a liquidar</label>
                <div className={styles.cotizacion_input_inicio}>
                  <input
                    value={total}
                    onChange={(e) => setTotal(e.target.value)}
                    disabled
                  />
                  <span>$</span>
                </div>
              </div>
            </div>

            {loading ? (
              <>Cargando ...</>
            ) : (
              <button
                form="cotizacion_form"
                type="submit"
                className={styles.okButton}
              >
                Contactado
              </button>
            )}
          </div>
        </form>

        <form>
          <div className={styles.comentario_form}>
            <div className={styles.comentario_cabecera}>
              <h1>Da más detalles</h1>
            </div>
            <div className={styles.cotizacion_cuerpo}>
              <textarea
                required
                value={nota}
                onChange={(e) => {
                  setNota(e.target.value);
                }}
              ></textarea>

              {loading ? (
                <>Cargando ...</>
              ) : (
                <Fragment>
                  <button
                    onClick={handleCommentSubmit}
                    className={styles.passiveButton}
                  >
                    No contesta
                  </button>

                  <button
                    className={styles.badButton}
                    onClick={(e) => {
                      handleCancelaSubmit(100, e);
                    }}
                  >
                    No hay respuesta
                  </button>

                  <button
                    className={styles.badButton}
                    onClick={(e) => {
                      handleCancelaSubmit(101, e);
                    }}
                  >
                    No le interesa
                  </button>
                  <button
                    className={styles.badButton}
                    onClick={(e) => {
                      handleCancelaSubmit(99, e);
                    }}
                  >
                    Gastos médicos
                  </button>
                </Fragment>
              )}
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default SinContactarComponent;

import SinAsiganarComponent from "./SinAsignar";
import SinContactarComponent from "./SinContactar";
import SinAutorizarComponent from "./SinAutorizar";
import SinPagoComponent from "./SinPago";
import SinConfirmacionComponent from "./SinConfirmacion";
import SinCotizacionComponent from "./SinCotizacion";

import ReasignarComponet from "./Reasignar";
import CanceladoComponent from "./Cancelado";
import Espera from "./Espera";

import styles from "./ClientDetails.module.scss";
import Confirmado from "./Confirmado";

const ClientDetails = ({
  details,
  show,
  hide,

  asignarCliente,

  clienteContactado,
  clienteSeguimiento,
  clienteAutorizado,
  registrarPago,
  confirmarPago,
  confirmarFicha,

  agregarNota,
  aplazarContacto,
  cancelar,
  rechazar,
  retirar,

  admin,
  enviarSMS,

  activeClient,
}) => {
  if (!details) return null;

  // HACK: Reconstruir vistas para reusabilidad

  return (
    <div className={`${styles.contenedor} ${show ? styles.show : styles.hide}`}>
      {
        {
          0: (
            <SinAsiganarComponent
              details={details}
              asignarCliente={asignarCliente}
              hide={hide}
              activeClient={activeClient}
            />
          ),
          1: (
            <SinContactarComponent
              details={details}
              hide={hide}
              clienteContactado={clienteContactado}
              agregarNota={agregarNota}
              aplazarContacto={aplazarContacto}
              cancelar={cancelar}
              rechazar={rechazar}
              retirar={retirar}
              admin={admin}
              enviarSMS={enviarSMS}
            />
          ),

          2:
            details.nueva_solicitud !== 2 ? (
              <SinCotizacionComponent
                details={details}
                hide={hide}
                clienteSeguimiento={clienteSeguimiento}
                agregarNota={agregarNota}
                aplazarContacto={aplazarContacto}
                cancelar={cancelar}
                rechazar={rechazar}
                retirar={retirar}
                admin={admin}
                enviarSMS={enviarSMS}
              />
            ) : (
              <SinContactarComponent
                details={details}
                hide={hide}
                clienteContactado={clienteContactado}
                agregarNota={agregarNota}
                aplazarContacto={aplazarContacto}
                cancelar={cancelar}
                rechazar={rechazar}
                retirar={retirar}
                admin={admin}
                enviarSMS={enviarSMS}
              />
            ),

          3:
            details.nueva_solicitud !== 2 ? (
              <SinAutorizarComponent
                details={details}
                hide={hide}
                clienteAutorizado={clienteAutorizado}
                agregarNota={agregarNota}
                aplazarContacto={aplazarContacto}
                cancelar={cancelar}
                retirar={retirar}
                admin={admin}
              />
            ) : (
              <SinContactarComponent
                details={details}
                hide={hide}
                clienteContactado={clienteContactado}
                agregarNota={agregarNota}
                aplazarContacto={aplazarContacto}
                cancelar={cancelar}
                rechazar={rechazar}
                retirar={retirar}
                admin={admin}
                enviarSMS={enviarSMS}
              />
            ),
          4:
            details.nueva_solicitud !== 2 ? (
              <SinPagoComponent
                details={details}
                hide={hide}
                registrarPago={registrarPago}
                agregarNota={agregarNota}
                aplazarContacto={aplazarContacto}
                cancelar={cancelar}
                retirar={retirar}
                admin={admin}
              />
            ) : (
              <SinContactarComponent
                details={details}
                hide={hide}
                clienteContactado={clienteContactado}
                agregarNota={agregarNota}
                aplazarContacto={aplazarContacto}
                cancelar={cancelar}
                rechazar={rechazar}
                retirar={retirar}
                admin={admin}
                enviarSMS={enviarSMS}
              />
            ), //Confirmacion pago
          5: admin ? (
            <SinConfirmacionComponent
              details={details}
              hide={hide}
              confirmarFicha={confirmarFicha}
              confirmarPago={confirmarPago}
              agregarNota={agregarNota}
              aplazarContacto={aplazarContacto}
              cancelar={cancelar}
              retirar={retirar}
              admin={admin}
            />
          ) : details.nueva_solicitud !== 2 ? (
            <SinConfirmacionComponent
              details={details}
              hide={hide}
              confirmarPago={confirmarPago}
              agregarNota={agregarNota}
              aplazarContacto={aplazarContacto}
              cancelar={cancelar}
              retirar={retirar}
              admin={admin}
            />
          ) : (
            <SinContactarComponent
              details={details}
              hide={hide}
              clienteContactado={clienteContactado}
              agregarNota={agregarNota}
              aplazarContacto={aplazarContacto}
              cancelar={cancelar}
              rechazar={rechazar}
              retirar={retirar}
              admin={admin}
              enviarSMS={enviarSMS}
            />
          ),

          //  TODO: Construir interface para confirmacion de pago
          6:
            details.nueva_solicitud !== 2 ? (
              <CanceladoComponent details={details} hide={hide} />
            ) : (
              <SinContactarComponent
                details={details}
                hide={hide}
                clienteContactado={clienteContactado}
                agregarNota={agregarNota}
                aplazarContacto={aplazarContacto}
                cancelar={cancelar}
                rechazar={rechazar}
                retirar={retirar}
                admin={admin}
                enviarSMS={enviarSMS}
              />
            ),

          7:
            details.nueva_solicitud !== 2 ? (
              <Confirmado
                details={details}
                hide={hide}
                clienteContactado={clienteContactado}
                agregarNota={agregarNota}
                aplazarContacto={aplazarContacto}
                cancelar={cancelar}
                retirar={retirar}
                admin={admin}
              />
            ) : (
              <SinContactarComponent
                details={details}
                hide={hide}
                clienteContactado={clienteContactado}
                agregarNota={agregarNota}
                aplazarContacto={aplazarContacto}
                cancelar={cancelar}
                rechazar={rechazar}
                retirar={retirar}
                admin={admin}
                enviarSMS={enviarSMS}
              />
            ), //  Sin contacto adicional
          8:
            details.nueva_solicitud !== 2 ? (
              <>Por asignar adicional</>
            ) : (
              <SinContactarComponent
                details={details}
                hide={hide}
                clienteContactado={clienteContactado}
                agregarNota={agregarNota}
                aplazarContacto={aplazarContacto}
                cancelar={cancelar}
                rechazar={rechazar}
                retirar={retirar}
                admin={admin}
                enviarSMS={enviarSMS}
              />
            ),
          9:
            details.nueva_solicitud !== 2 ? (
              <Espera
                details={details}
                hide={hide}
                clienteContactado={clienteContactado}
                agregarNota={agregarNota}
                aplazarContacto={aplazarContacto}
                cancelar={cancelar}
                retirar={retirar}
                admin={admin}
              />
            ) : (
              <SinContactarComponent
                details={details}
                hide={hide}
                clienteContactado={clienteContactado}
                agregarNota={agregarNota}
                aplazarContacto={aplazarContacto}
                cancelar={cancelar}
                rechazar={rechazar}
                retirar={retirar}
                admin={admin}
                enviarSMS={enviarSMS}
              />
            ),
          10:
            details.nueva_solicitud !== 2 ? (
              <>Seguimiento adicional</>
            ) : (
              <SinContactarComponent
                details={details}
                hide={hide}
                clienteContactado={clienteContactado}
                agregarNota={agregarNota}
                aplazarContacto={aplazarContacto}
                cancelar={cancelar}
                rechazar={rechazar}
                retirar={retirar}
                admin={admin}
                enviarSMS={enviarSMS}
              />
            ),

          50:
            details.nueva_solicitud !== 2 ? (
              <Espera
                details={details}
                hide={hide}
                registrarPago={registrarPago}
                agregarNota={agregarNota}
                aplazarContacto={aplazarContacto}
                cancelar={cancelar}
                retirar={retirar}
                admin={admin}
              />
            ) : (
              <SinContactarComponent
                details={details}
                hide={hide}
                clienteContactado={clienteContactado}
                agregarNota={agregarNota}
                aplazarContacto={aplazarContacto}
                cancelar={cancelar}
                rechazar={rechazar}
                retirar={retirar}
                admin={admin}
                enviarSMS={enviarSMS}
              />
            ),
          60:
            details.nueva_solicitud !== 2 ? (
              <Espera
                details={details}
                hide={hide}
                registrarPago={registrarPago}
                agregarNota={agregarNota}
                aplazarContacto={aplazarContacto}
                cancelar={cancelar}
                retirar={retirar}
                admin={admin}
              />
            ) : (
              <SinContactarComponent
                details={details}
                hide={hide}
                clienteContactado={clienteContactado}
                agregarNota={agregarNota}
                aplazarContacto={aplazarContacto}
                cancelar={cancelar}
                rechazar={rechazar}
                retirar={retirar}
                admin={admin}
                enviarSMS={enviarSMS}
              />
            ),
          /* 540: 0,  Datos sin solicitud solo viabilidad*/

          // No se pudo cobtaxctar
          100: admin ? (
            <ReasignarComponet
              details={details}
              asignarCliente={asignarCliente}
              hide={hide}
              retirar={retirar}
              admin={admin}
            />
          ) : details.nueva_solicitud !== 2 ? (
            <CanceladoComponent
              details={details}
              hide={hide}
              agregarNota={agregarNota}
            />
          ) : (
            <SinContactarComponent
              details={details}
              hide={hide}
              clienteContactado={clienteContactado}
              agregarNota={agregarNota}
              aplazarContacto={aplazarContacto}
              cancelar={cancelar}
              rechazar={rechazar}
              retirar={retirar}
              admin={admin}
              enviarSMS={enviarSMS}
            />
          ),

          // No le interesa
          101: admin ? (
            <ReasignarComponet
              details={details}
              asignarCliente={asignarCliente}
              hide={hide}
              retirar={retirar}
              admin={admin}
            />
          ) : details.nueva_solicitud !== 2 ? (
            <CanceladoComponent
              details={details}
              hide={hide}
              agregarNota={agregarNota}
            />
          ) : (
            <SinContactarComponent
              details={details}
              hide={hide}
              clienteContactado={clienteContactado}
              agregarNota={agregarNota}
              aplazarContacto={aplazarContacto}
              cancelar={cancelar}
              rechazar={rechazar}
              retirar={retirar}
              admin={admin}
              enviarSMS={enviarSMS}
            />
          ),

          // No contesta
          102: admin ? (
            <ReasignarComponet
              details={details}
              asignarCliente={asignarCliente}
              hide={hide}
              retirar={retirar}
              admin={admin}
            />
          ) : details.nueva_solicitud !== 2 ? (
            <CanceladoComponent
              details={details}
              hide={hide}
              agregarNota={agregarNota}
            />
          ) : (
            <SinContactarComponent
              details={details}
              hide={hide}
              clienteContactado={clienteContactado}
              agregarNota={agregarNota}
              aplazarContacto={aplazarContacto}
              cancelar={cancelar}
              rechazar={rechazar}
              retirar={retirar}
              admin={admin}
              enviarSMS={enviarSMS}
            />
          ),

          // No envia docs
          103: admin ? (
            <ReasignarComponet
              details={details}
              asignarCliente={asignarCliente}
              hide={hide}
              retirar={retirar}
              admin={admin}
            />
          ) : details.nueva_solicitud !== 2 ? (
            <CanceladoComponent
              details={details}
              hide={hide}
              agregarNota={agregarNota}
            />
          ) : (
            <SinContactarComponent
              details={details}
              hide={hide}
              clienteContactado={clienteContactado}
              agregarNota={agregarNota}
              aplazarContacto={aplazarContacto}
              cancelar={cancelar}
              rechazar={rechazar}
              retirar={retirar}
              admin={admin}
              enviarSMS={enviarSMS}
            />
          ),

          // No quiere pagar
          104: admin ? (
            <ReasignarComponet
              details={details}
              asignarCliente={asignarCliente}
              hide={hide}
              retirar={retirar}
              admin={admin}
            />
          ) : details.nueva_solicitud !== 2 ? (
            <CanceladoComponent
              details={details}
              hide={hide}
              agregarNota={agregarNota}
            />
          ) : (
            <SinContactarComponent
              details={details}
              hide={hide}
              clienteContactado={clienteContactado}
              agregarNota={agregarNota}
              aplazarContacto={aplazarContacto}
              cancelar={cancelar}
              rechazar={rechazar}
              retirar={retirar}
              admin={admin}
              enviarSMS={enviarSMS}
            />
          ),

          105:
            details.nueva_solicitud !== 2 ? (
              <></>
            ) : (
              <SinContactarComponent
                details={details}
                hide={hide}
                clienteContactado={clienteContactado}
                agregarNota={agregarNota}
                aplazarContacto={aplazarContacto}
                cancelar={cancelar}
                rechazar={rechazar}
                retirar={retirar}
                admin={admin}
                enviarSMS={enviarSMS}
              />
            ),
        }[details.estado]
      }
    </div>
  );
};

export default ClientDetails;

import styles from "./SearchBarComponent.module.scss";

const SearchBarComponent = ({ filter, setFilter }) => {
  return (
    <form
      className={styles.contenedor}
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <input
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        placeholder="Ingresa tu busqueda"
      ></input>
    </form>
  );
};

export default SearchBarComponent;

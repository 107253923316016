import { Fragment, useState } from "react";
import styles from "../ClientDetails.module.scss";
import CategoriaComponent from "../SinContactar/Categoria";
import { useEffect } from "react";

const SinConfirmacionComponent = ({
  details,
  hide,
  confirmarPago,
  confirmarFicha,
  aplazarContacto,
  cancelar,
  admin,
}) => {
  const [folio, setFolio] = useState("");
  const [porcentaje, setPorcentaje] = useState(".35");
  const [comision, setComision] = useState("");

  const [monto, setMonto] = useState("");
  const [montoComisionable, setMontoComisionable] = useState("");
  const [iva, setIva] = useState("");

  const [nota, setNota] = useState("");

  const [loading, setLoading] = useState(false);

  const resetForm = () => {
    setFolio("");
    setPorcentaje(".35");
    setComision("");
    setNota("");
  };

  useEffect(() => {
    if (!!details.fichas) {
      setMonto(details.fichas.slice(-1)[0].monto);
    }
    setMontoComisionable(((parseInt(monto) * 100) / 116).toFixed(2).toString());
    setIva((((parseInt(monto) * 100) / 116) * 0.16).toFixed(2).toString());
  });

  useEffect(() => {
    setComision((montoComisionable * porcentaje).toFixed(2).toString());
  }, [montoComisionable, porcentaje]);

  // const handleSubmitPago = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();

  //   setLoading(true);

  //   confirmarFicha();
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const data = {
      nombre: details.nombre,
      id: details._id,
      folio: folio,
      concepto: details.fichas.slice(-1)[0].concepto,
      monto_comisionable: montoComisionable,
      iva: iva,
      monto: monto,
      comision_porcentaje: porcentaje,
      razon: details.fichas.slice(-1)[0].razon,
      cuenta: details.fichas.slice(-1)[0].cuenta,
      marca: details.fichas.slice(-1)[0].marca,
      numero_Pago: 1,
      asesor_nombre: details.fichas.slice(-1)[0].asesor_nombre,
      asesor_id: details.fichas.slice(-1)[0].asesor_id,
      comision: comision,
      referencia: "",
    };

    setLoading(true);

    confirmarPago(data);

    // Cambiar funcion a confirmar pago
    //setLoading(registrarPago(details._id, 0, {}));
  };

  const handleCommentSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (nota === "") {
      return;
    }
    setLoading(aplazarContacto(details._id, nota));
  };

  const handleCancelaSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (nota === "") {
      return;
    }

    setLoading(true);
    setLoading(await cancelar(details._id, 106, nota));
  };

  console.log(details.fichas.at(-1));

  return (
    <div>
      <div className={styles.cabecera}>
        <h1>Esperando confirmacion</h1>
        <button
          onClick={() => {
            resetForm();
            hide();
          }}
          className={styles.closeBttn}
        >
          <span className="material-symbols-outlined">close</span>
        </button>
      </div>

      <div className={styles.body}>
        <div className={styles.tarjetaDatos}>
          <h1 style={{ textAlign: "center" }}>
            <b>{details.fecha_actualizacion}</b>
          </h1>
          <br />
          <h1 style={{ textAlign: "center" }}>{details.nombre}</h1>
        </div>

        {!details.solicitud ? (
          <></>
        ) : (
          <Fragment>
            <span style={{ border: ".5px solid #02394a" }} />
            <p>Solicitudes: {details.solicitud.length}</p>

            {details.solicitud.map((item, id) => (
              <CategoriaComponent
                key={`categoria_solicitud_${id}`}
                tipo="solicitud_nueva"
                fecha={item.fecha}
                data={item}
              />
            ))}
          </Fragment>
        )}

        {!details.viabilidad ? (
          <></>
        ) : (
          <Fragment>
            <span style={{ border: ".5px solid #02394a" }} />
            <p>Viabilidad: {details.solicitud.length}</p>
            {details.viabilidad.map((item, id) => (
              <CategoriaComponent
                key={`categoria_viabilidad_${id}`}
                tipo={
                  item.tipo === "Persona fisica"
                    ? "viabilidadFisica"
                    : "viabilidadMoral"
                }
                fecha={item.fecha}
                data={item}
              />
            ))}
          </Fragment>
        )}

        {/* TODO:Agregar fichas a los demas componenetes */}
        {!details.fichas ? (
          <></>
        ) : (
          <Fragment>
            <span style={{ border: ".5px solid #02394a" }} />
            <p>Pagos: {details.fichas.length}</p>

            {details.fichas.slice(-1).map((item, id) => (
              <CategoriaComponent
                key={`categoria_ficha_${id}`}
                tipo="ficha"
                fecha={item.fecha_asignado}
                data={item}
              />
            ))}
          </Fragment>
        )}

        {/* {activeClient === 0 ? ( */}
        {admin ? (
          <Fragment>
            {/* {details.fichas.at(-1).confirmado === 'true' ? (
              <>Pago confirmado</>
            ) : (
              <form onSubmit={handleSubmitPago} id="auth_pago_form">
                <div className={styles.cotizacion_form}>
                  <div className={styles.cotizacion_cabecera}>
                    <h1>Confirmar pago </h1>
                  </div>

                  <div className={styles.cotizacion_cuerpo}></div>

                  {loading ? (
                    <>Cargando ...</>
                  ) : (
                    <Fragment>
                      <button
                        form="auth_pago_form"
                        type="submit"
                        className={styles.okButton}
                      >
                        Confirmado
                      </button>
                      {/* <br></br>
                    <br></br>
                    <button
                      form="auth_form"
                      type="submit"
                      disabled
                      className={styles.disabled}
                    >
                      No aparece
                    </button> */}
                    {/* </Fragment>
                  )}
                </div>
              </form>
            )} */} 

            <form onSubmit={handleSubmit} id="auth_form">
              <div className={styles.cotizacion_form}>
                <div className={styles.cotizacion_cabecera}>
                  <h1>Confirmar pago</h1>
                </div>

                <div className={styles.cotizacion_cuerpo}>
                  <div className={styles.cotizacion_field}>
                    <label>Folio</label>
                    <div className={styles.cotizacion_input_inicio}>
                      <input
                        required
                        value={folio}
                        onChange={(e) => {
                          setFolio(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className={styles.cotizacion_field}>
                    <label>Porcentaje comision</label>
                    <select
                      value={porcentaje}
                      onChange={(e) => setPorcentaje(e.target.value)}
                    >
                      <option value=".35">35%</option>
                      <option value=".20">20%</option>
                      <option value=".15">15%</option>
                    </select>
                  </div>

                  <div className={styles.cotizacion_field}>
                    <label>Monto comision</label>
                    <div className={styles.cotizacion_input_inicio}>
                      <input
                        disabled
                        required
                        value={parseInt(comision.replace(/\./g, ""), 10)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        onChange={(e) => {
                          setComision(e.target.value);
                        }}
                      />
                      <span>$</span>
                    </div>
                  </div>
                </div>

                {loading ? (
                  <>Cargando ...</>
                ) : (
                  <button
                    form="auth_form"
                    type="submit"
                    className={styles.okButton}
                  >
                    Confirmar pago
                  </button>
                )}
              </div>
            </form>

            <span style={{ border: ".5px solid #02394a" }} />

            <form>
              <div className={styles.comentario_form}>
                <div className={styles.comentario_cabecera}>
                  <h1>Hay un problema</h1>
                </div>
                <div className={styles.cotizacion_cuerpo}>
                  <textarea
                    required
                    value={nota}
                    onChange={(e) => setNota(e.target.value)}
                  ></textarea>

                  {loading ? (
                    <>Cargando ...</>
                  ) : (
                    <Fragment>
                      <button
                        type="submit"
                        onClick={handleCommentSubmit}
                        className={styles.passiveButton}
                      >
                        No aparece el pago
                      </button>

                      <button
                        type="submit"
                        className={styles.badButton}
                        onClick={handleCancelaSubmit}
                      >
                        Pago falso
                      </button>
                    </Fragment>
                  )}
                </div>
              </div>
            </form>
          </Fragment>
        ) : (
          <></>
        )}

        {/* <CategoriaComponent tipo="datos" data={details} /> */}

        {/* {admin ? (
          <button
            className={styles.badButton}
            onClick={() => {
              setLoading(retirar(details._id));
            }}
          >
            Retirar para reciclaje
          </button>
        ) : (
          <></>
        )} */}

        {/* <span style={{ border: ".5px solid #02394a" }} />
        <p>Asignaciones anteriores: {details.pago.length}</p>

        {details.pago.map((item, id) => (
          <CategoriaComponent
            key={`categoria_pago_${id}`}
            tipo="asignacion"
            fecha={item.fecha_asignado}
            data={item}
          />
        ))} */}
        {/* 
        {!details.solicitud ? (
          <></>
        ) : (
          <Fragment>
            <span style={{ border: ".5px solid #02394a" }} />
            <p>Solicitudes: {details.solicitud.length}</p>

            {details.solicitud.map((item, id) => (
              <CategoriaComponent
                key={`categoria_solicitud_${id}`}
                tipo="solicitud_nueva"
                fecha={item.fecha}
                data={item}
              />
            ))}
          </Fragment>
        )} */}

        {/* {!details.viabilidad ? (
          <></>
        ) : (
          <Fragment>
            <span style={{ border: ".5px solid #02394a" }} />
            <p>Viabilidad: {details.solicitud.length}</p>
            {details.viabilidad.map((item, id) => (
              <CategoriaComponent
                key={`categoria_viabilidad_${id}`}
                tipo={
                  item.tipo === "Persona fisica"
                    ? "viabilidadFisica"
                    : "viabilidadMoral"
                }
                fecha={item.fecha}
                data={item}
              />
            ))}
          </Fragment>
        )} */}

        {/* <span style={{ border: ".5px solid #02394a" }} />

        {details.cotizaciones.map((item, id) => (
          <CategoriaComponent
            key={`categoria_cotiza_${id}`}
            tipo="cotizacion"
            fecha={item.fecha_cotizacion}
            data={item}
          />
        ))} */}

        {/* <span style={{ border: ".5px solid #02394a" }} />

        {details.aprobaciones.map((item, id) => (
          <CategoriaComponent
            key={`categoria_aprobacion_${id}`}
            tipo="aprobacion"
            fecha={item.fecha_aprobacion}
            data={item}
          />
        ))} */}
        {/*
     
         */}
      </div>
    </div>
  );
};

export default SinConfirmacionComponent;

import { Fragment, useState } from "react";
import styles from "../ClientDetails.module.scss";
import CategoriaComponent from "../SinContactar/Categoria";

const ConfirmadoComponent = ({
  details,
  hide,
  registrarPago,
  cancelar,
  retirar,
  admin,
}) => {
  const [monto, setMonto] = useState("0");
  const [concepto, setConcepto] = useState("");
  const [marca, setMarca] = useState("Bien Crediticio");
  const [cuenta, setCuenta] = useState("BBVA Respaldo");
  const [razon, setRazon] = useState("Apoyando tu futuro SAPI de CV");
  const [nota, setNota] = useState("");

  const [loading, setLoading] = useState(false);

  const resetForm = () => {
    setMonto(0);
    setConcepto("");
    setMarca("Bien Crediticio");
    setCuenta("BBVA Respaldo");
    setRazon("Apoyando tu futuro SAPI de CV");
    setNota("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setLoading(true);

    registrarPago(details._id, 0, {
      monto,
      concepto,
      marca,
      cuenta,
      razon,
    }).then((res) => setLoading(false));
  };

  const handleCancelaSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (nota === "") {
      return;
    }

    setLoading(true);
    setLoading(await cancelar(details._id, 104, nota));
  };

  return (
    <div>
      <div className={styles.cabecera}>
        <h1>Pago confirmado</h1>
        <button
          onClick={() => {
            resetForm();
            hide();
          }}
          className={styles.closeBttn}
        >
          <span className="material-symbols-outlined">close</span>
        </button>
      </div>

      <div className={styles.body}>
        <div className={styles.tarjetaDatos}>
          <h1 style={{ textAlign: "center" }}>
            <b>{details.fecha_actualizacion}</b>
          </h1>
          <br />
          <h1 style={{ textAlign: "center" }}>{details.folio}</h1>
          <br />
          <h1 style={{ textAlign: "center" }}>{details.nombre}</h1>
        </div>

        <CategoriaComponent tipo="datos" data={details} />

        {admin ? (
          <button
            className={styles.badButton}
            onClick={() => {
              setLoading(retirar(details._id));
            }}
          >
            Retirar para reciclaje
          </button>
        ) : (
          <></>
        )}

        {!details.pago ? (
          <></>
        ) : (
          <Fragment>
            <span style={{ border: ".5px solid #02394a" }} />
            <p>Asignaciones anteriores: {details.pago.length}</p>
            {details.pago.map((item, id) => (
              <CategoriaComponent
                key={`categoria_pago_${id}`}
                tipo="asignacion"
                fecha={item.fecha_asignado}
                data={item}
              />
            ))}
          </Fragment>
        )}

        <span style={{ border: ".5px solid #000" }} />
        <p>Solicitudes: {details.solicitud.length}</p>

        {details.solicitud.map((item, id) => (
          <CategoriaComponent
            key={`categoria_solicitud_${id}`}
            tipo="solicitud"
            fecha={item.fecha}
            data={item}
          />
        ))}

        {!details.viabilidad ? (
          <></>
        ) : (
          <Fragment>
            <span style={{ border: ".5px solid #02394a" }} />
            <p>Viabilidad: {details.solicitud.length}</p>
            {details.viabilidad.map((item, id) => (
              <CategoriaComponent
                key={`categoria_viabilidad_${id}`}
                tipo={
                  item.tipo === "Persona fisica"
                    ? "viabilidadFisica"
                    : "viabilidadMoral"
                }
                fecha={item.fecha}
                data={item}
              />
            ))}
          </Fragment>
        )}

        <span style={{ border: ".5px solid #02394a" }} />

        {!details.cotizaciones ? (
          <></>
        ) : (
          <Fragment>
            <span style={{ border: ".5px solid #02394a" }} />

            {details.cotizaciones.map((item, id) => (
              <CategoriaComponent
                key={`categoria_cotiza_${id}`}
                tipo="cotizacion"
                fecha={item.fecha_cotizacion}
                data={item}
              />
            ))}
          </Fragment>
        )}

        {!details.aprobaciones ? (
          <></>
        ) : (
          <Fragment>
            <span style={{ border: ".5px solid #02394a" }} />

            {details.aprobaciones.map((item, id) => (
              <CategoriaComponent
                key={`categoria_aprobacion_${id}`}
                tipo="aprobacion"
                fecha={item.fecha_aprobacion}
                data={item}
              />
            ))}
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default ConfirmadoComponent;

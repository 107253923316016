import { initializeApp } from "firebase/app";
import {
  browserLocalPersistence,
  getAuth,
  setPersistence,
  signInWithEmailAndPassword,
  signOut,
  //updateProfile,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyATEVUQo3MmyYOXUivkeIf7lXZFhtG1y28",
  authDomain: "zerod-crm.firebaseapp.com",
  projectId: "zerod-crm",
  storageBucket: "zerod-crm.appspot.com",
  messagingSenderId: "604691102806",
  appId: "1:604691102806:web:90586bdb178e7985e90ff7",
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

const login = (email, passwd) => {
  setPersistence(auth, browserLocalPersistence).then(() => {
    return signInWithEmailAndPassword(auth, email, passwd);
  });
};

const loginChange = (callback) => {
  auth.onAuthStateChanged(callback);
  //auth.onIdTokenChanged(callback);
};

const getToken = () => {
  // updateProfile(auth.currentUser, {
  //     displayName: "Gerencia",
  // });

  return auth.currentUser.getIdToken(true);
};

const report = (message, accion, usuario) => {
  fetch(`https://ntfy.sh/wikirp_soporte_bien`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-type": "text/plain",
    },
    body: JSON.stringify({ error: message, accion, usuario }),
  });
};

const closeSession = () => {
  signOut(auth);
};

export { auth, login, loginChange, getToken, closeSession, report };

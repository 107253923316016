import { createContext, useReducer } from "react";
import { UserReducer } from "./UserReducer";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [userData, changeUser] = useReducer(UserReducer, {
        data: null,
        logged: false,
    });

    return (
        <UserContext.Provider value={{ userData, changeUser }}>
            {children}
        </UserContext.Provider>
    );
};

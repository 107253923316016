import { closeSession } from "../../extras/firebase";
import styles from "./SideMenuComponent.module.scss";

const SideMenuComponent = ({ show, setShow }) => {
  return (
    <div
      className={`${styles.container} ${show ? styles.show : styles.hide}`}
      onClick={() => setShow(false)}
    >
      <div className={styles.cuerpo} onClick={(e) => e.stopPropagation()}>
        <div className={styles.cabecera}>
          <h1>Hola</h1>
          <button
            onClick={() => {
              setShow(false);
            }}
            className={styles.closeBttn}
          >
            <span className="material-symbols-outlined">close</span>
          </button>
        </div>

        <div className={styles.pie}>
          <button onClick={() => closeSession()}>Cerrar sesión</button>
        </div>
      </div>
    </div>
  );
};

export default SideMenuComponent;

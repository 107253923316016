import { useState } from "react";
import { login } from "../../extras/firebase";

import styles from "./Login.module.scss";

const LoginComponent = () => {
    const [email, setEmail] = useState("");
    const [passwd, setPasswd] = useState("");

    return (
        <div className={styles.container}>
            <form
                autoComplete="off"
                onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    login(email, passwd);
                }}
            >
                <div className={styles.field}>
                    <label>Correo electrónico</label>
                    <input
                        required
                        pattern=".+@gmail.com|.+@prestamo24.mx|.+@zerodeudas.com.mx|.+@proyeccionfinancieraonline.com.mx|.+@biencrediticio.com|.+@biencrediticio.com.mx"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value.trim())}
                    ></input>
                </div>

                <div className={styles.field}>
                    <label>Contraseña</label>
                    <input
                        required
                        type="password"
                        value={passwd}
                        onChange={(e) => setPasswd(e.target.value.toString())}
                    ></input>
                </div>

                <button type="submit" className={styles.loginButton}>
                    Iniciar
                </button>
            </form>
        </div>
    );
};

export default LoginComponent;

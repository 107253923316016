export const UserReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN_USER": {
      return {
        data: action.payload,
        logged: true,
      };
    }

    case "LOGOUT_USER":
      return {
        data: null,
        logged: false,
      };

    default:
      return state;
  }
};

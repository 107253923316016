import { Navigate, Outlet } from "react-router-dom";

const PrivateRouter = ({ children, logged, redirectPath = "/login" }) => {
    if (!logged) {
        return <Navigate to={redirectPath} replace />;
    }
    return children ? children : <Outlet />;
};

export default PrivateRouter;

import styles from "./ChipComponent.module.scss";

const ChipComponent = ({ activeChip, setActiveChip, title, count }) => {
  let isActive = activeChip === title;

  return (
    <span
      className={`${isActive ? styles.chipActive : styles.chipDeactive} ${
        styles.chip
      }`}
      onClick={() => {
        setActiveChip(title);
      }}
    >
      {title} {isActive ? count : ""}
    </span>
  );
};

export default ChipComponent;

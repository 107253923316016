import styles from "./ChipContainerComponent.module.scss";
import Chip from "./Chip";

const ChipContainerComponent = ({ chips, ...rest }) => {
  return (
    <div className={styles.chipsContainer}>
      {chips.map((item, key) => (
        <Chip title={item} key={key} {...rest} />
      ))}
    </div>
  );
};

export default ChipContainerComponent;
